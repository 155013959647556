var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "start-a-business" },
        [
          _c("progress-steps", {
            attrs: {
              subtitle: _vm.subtitle,
              "progress-status": _vm.progressStatus,
            },
          }),
          _c("div", { staticClass: "main-container" }, [
            _c("h2", { staticClass: "sl__h2 container-header" }, [
              _vm._v("\n      Select Jurisdiction\n    "),
            ]),
            _c(
              "div",
              { staticClass: "container-body" },
              [
                _c("vue-multiselect", {
                  staticClass: "selection-body",
                  attrs: {
                    placeholder: "-- Select State --",
                    label: "state_province_region",
                    value: _vm.jurisdiction,
                    options: _vm.jurisdictions,
                    multiple: false,
                    searchable: true,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.onSelectedJurisdictionDropdown($event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("button-container", {
            attrs: {
              "next-button-label": _vm.nextButtonLabel,
              "show-previous-button": _vm.showPreviousButton,
              "disable-next-button": _vm.disableNextButton,
            },
            on: { next: _vm.next },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }