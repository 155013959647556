<template>
  <div v-if="loaded" class="start-a-business">
    <progress-steps
      :subtitle="subtitle"
      :progress-status="progressStatus"
    />

    <div class="main-container">
      <h2 class="sl__h2 container-header">
        Select Jurisdiction
      </h2>
      <div class="container-body">
        <vue-multiselect
          class="selection-body"
          placeholder="-- Select State --"
          label="state_province_region"
          :value="jurisdiction"
          :options="jurisdictions"
          :multiple="false"
          :searchable="true"
          @input="onSelectedJurisdictionDropdown($event)"
        />
      </div>
    </div>

    <button-container
      :next-button-label="nextButtonLabel"
      :show-previous-button="showPreviousButton"
      :disable-next-button="disableNextButton"
      @next="next"
    />
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SelectJurisdiction',
  components: {
    VueMultiselect,
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    ProgressSteps: () => import('@/components/StagelineV2/domainsFirst/shared/ProgressSteps'),
    ButtonContainer: () => import('@/components/StagelineV2/domainsFirst/shared/ButtonContainer'),
  },
  data () {
    return {
      subtitle: 'jurisdiction',
      progressStatus: 20,
      nextButtonLabel: 'Next: Select Entity Type',
      showPreviousButton: true,
    }
  },
  computed: {
    ...mapGetters('jurisdictions', [
      'jurisdictions',
    ]),
    ...mapGetters('stagelineCreateCompany', [
      'jurisdiction',
      'loaded',
    ]),
    disableNextButton() {
      return this.jurisdiction == null
    },
  },
  async mounted() {
    this.showLoading()
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'setCompanyJurisdiction',
      'setCurrentSlide',
      'setLoaded',
    ]),
    onSelectedJurisdictionDropdown(event) {
      const jurisdictionId = event.id
      if (jurisdictionId) {
        const jurisdiction = this.jurisdictions.find(j => j.id === jurisdictionId)
        this.selectedJurisdiction(jurisdiction)
      }
    },
    selectedJurisdiction(jurisdiction) {
      this.setCompanyJurisdiction(jurisdiction)
    },
    showLoading() {
      this.setLoaded(false)
      setTimeout(() => {  this.setLoaded(true) }, 500)
    },
    next() {
      this.setCurrentSlide('SelectEntityType')
    },
  },
}
</script>

<style lang="scss" scoped>
.sl__h2 {
  text-align: left;
}
</style>
